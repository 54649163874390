import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
//angularFire services
import { redirectUnauthorizedTo, AngularFireAuthGuard, AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { InactiveGuard } from './guards/inactive.guard';
import { InactiveComponent } from './pages/inactive/inactive.component';

//redirect unthorized user to login page
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['connexion']);

//app route is the entry point of firecommerce admin
const routes: Routes = [
  //{ path: "**", redirectTo: "maintenance" },
  { path: "", pathMatch: 'full', redirectTo: 'accueil' },
  { path: "maintenance", loadChildren: () => import('./pages/maintenance/maintenance.module').then(m => m.MaintenanceModule) },
  { path: "inactif", component: InactiveComponent },
  { path: "statut-paiement", loadChildren: () => import('./pages/paiement-status/paiement-status.module').then(m => m.PaiementStatusModule) },
  { path: "dashboard", loadChildren: () => import('./pages/main/main.module').then(m => m.MainModule), canActivate: [AngularFireAuthGuard, InactiveGuard], data: { authGuardPipe: redirectUnauthorizedToLogin } },
  { path: "connexion", loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
  { path: "enregistrement", loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule) },
  { path: "accueil", loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
  { path: "termes-et-condition", loadChildren: () => import('./pages/term-of-use/term-of-use.module').then(m => m.TermOfUseModule) },
  { path: "politique", loadChildren: () => import('./pages/policy/policy.module').then(m => m.PolicyModule) },
  { path: "faq", loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqModule) },
  { path: "rechargement", loadChildren: () => import('./pages/purchase/purchase.module').then(m => m.PurchaseModule), canActivate: [AngularFireAuthGuard, InactiveGuard], data: { authGuardPipe: redirectUnauthorizedToLogin } },
  { path: "bibleinfo", loadChildren: () => import('./pages/bible-info/bible-info.module').then(m => m.BibleInfoModule)},
]

@NgModule({
  imports: [
    AngularFireAuthGuardModule,
    RouterModule.forRoot(routes),
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
