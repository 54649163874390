import { Component, OnInit } from '@angular/core';
import { ProfilService } from 'src/app/services/user/profil.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-inactive',
  templateUrl: './inactive.component.html',
  styleUrls: ['./inactive.component.scss']
})
export class InactiveComponent implements OnInit {

  profil: any;

  constructor(
    private profilSv: ProfilService,
  ) {


  }

  ngOnInit() {
    let uid = localStorage.getItem('uid');
    if (uid)
      this.profilSv.getProfil().pipe(first()).toPromise().then(profil => this.profil = profil);

    let element = document.getElementById("header");
    if (element)
      element.remove()
  }

  copy() {
    navigator.clipboard.writeText(this.profil.accountId);
  }

}
