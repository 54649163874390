import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ProfilService } from '../services/user/profil.service';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InactiveGuard implements CanActivate {
  constructor(
    private profilSv: ProfilService,
    private router: Router
  ) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    let uid = localStorage.getItem('uid');

    if (!uid){
      this.router.navigate(["/connexion"]);
      return Promise.resolve(false);
    }

    return this.profilSv.getProfil().pipe(first()).toPromise().then(d => {
      if (!d.verified)
        this.router.navigate(["/inactif"]);
      return d.verified
    });
  }

}
