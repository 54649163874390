import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  makeid(length) {
    let result = '';
    let characters = '0123456789AZERTYUIOPQSDFGHJKLMWXCVBN';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }


  async  asyncForEach(array: Array<any>, callback: Function) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  wordClean(text : string) {

    let cleanStr: string = text;

    // smart single quotes and apostrophe
    cleanStr = cleanStr.replace(/[\u2018\u2019\u201A]/g, "\'");

    // smart double quotes
    cleanStr = cleanStr.replace(/[\u201C\u201D\u201E]/g, "\"");

    // ellipsis
    cleanStr = cleanStr.replace(/\u2026/g, "…");

    // dashes
    cleanStr = cleanStr.replace(/[\u2013\u2014]/g, "-");

    // circumflex
    cleanStr = cleanStr.replace(/\u02C6/g, "^");

    // open angle bracket
    cleanStr = cleanStr.replace(/\u2039/g, "<");

    // close angle bracket
    cleanStr = cleanStr.replace(/\u203A/g, ">");

    // spaces
    cleanStr = cleanStr.replace(/[\u02DC\u00A0]/g, " ");

    return cleanStr;
  }

}
