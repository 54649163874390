import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import 'firebase/analytics';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'firecommerce-admin-ng';

  constructor(
    auth: AngularFireAuth,
    translate : TranslateService
    ) {

      //initialisation de la langue
      let lang = localStorage.getItem("lang") || navigator.language.substr(0,2).toLowerCase();
      localStorage.setItem("lang", lang);

      translate.setDefaultLang('fr');
      translate.use("fr");

  }

  ngOnInit(){
    firebase.analytics();
  }

}
