import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { shareReplay, first } from "rxjs/operators"
import { Observable } from 'rxjs';
import { UtilsService } from '../helper/utils.service';
import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class ProfilService {

  private profilObserver: Observable<any>
  private profilObservers: Observable<any>
  private profilRef: AngularFirestoreCollection<any>
  private uid

  constructor(
    public firestore: AngularFirestore,
    private utils: UtilsService
  ) {
    this.uid = localStorage.getItem('uid')
    this.profilRef = this.firestore.collection('profils');

    if (this.uid)
      this.profilObserver = this.profilRef.doc(this.uid).valueChanges().pipe(shareReplay(1))

    this.profilObservers = this.profilRef.valueChanges().pipe(shareReplay(1))
  }

  getProfil() {
    return this.profilObserver
  }

  getProfilById(accountId) {
    return this.firestore.collection('profils', q => q.where('accountId', "==", accountId.toUpperCase())).valueChanges().pipe(first())
  }


  updateProfil(profil) {
    return this.profilRef.doc(this.uid).update(profil)
  }

  getProfils() {

  }

  updateApi() {
    let apiId = this.utils.makeid(30)
    return this.profilRef.doc(this.uid).update({
      apiId: apiId
    })
  }

  updatePassword(newPassword) {
    let user = firebase.auth().currentUser;
    return user.updatePassword(newPassword)
  }
}
